import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .card-image {
    position: relative;
    margin-bottom: 16px;
    border-radius: 4px;
    overflow: hidden;

    &::before {
      content: '';
      padding-top: 60%;
      display: flex;
    }

    .img-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .featured-blog-bg {
    background-color: var(--primary-color);

    img {
      width: 200px;
      max-width: 100%;
    }
  }

  .card-content {
    h5 {
      text-transform: capitalize;
      letter-spacing: 0;
      line-height: 22px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .blog-desc {
      margin-bottom: 8px;
    }
  }

  .readmore {
    a {
      font-size: 14.4px;
      line-height: 20.16px;
      text-transform: capitalize;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 7px;
        right: -20px;
        top: 2px;
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%226%22%20height%3D%228%22%20viewBox%3D%220%200%206%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5.06466%203.1553C5.54962%203.55529%205.54962%204.29823%205.06466%204.69821L2.38629%206.9073C1.73405%207.44526%200.75%206.98131%200.75%206.13585L0.75%201.71767C0.75%200.872203%201.73404%200.408257%202.38628%200.946215L5.06466%203.1553Z%22%20fill%3D%22%231C3FFF%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
      }

      &:hover {
        text-decoration: none;
        
        &::after {
          background-image: url('data:image/svg+xml,%3Csvg%20width%3D%226%22%20height%3D%228%22%20viewBox%3D%220%200%206%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5.06466%203.1553C5.54962%203.55529%205.54962%204.29823%205.06466%204.69821L2.38629%206.9073C1.73405%207.44526%200.75%206.98131%200.75%206.13585L0.75%201.71767C0.75%200.872203%201.73404%200.408257%202.38628%200.946215L5.06466%203.1553Z%22%20fill%3D%22%230056b3%22%2F%3E%3C%2Fsvg%3E');
        }
      }
    }
  }
`;
