import React from 'react';
import logo from '../../images/sm-light-logo.svg';
import Img from '../../utils/OptimizedImage';
import { Card } from '../../styles/Components/BlogCardDeck/FeaturedCardNoStyles';
import PersistLink from '../PersistLink/PersistLink';

const FeaturedCardNoBg = ({ blog }) => {
  return (
    <Card>
      <div className="card-wrapper">
        <div className="card-image">
          {blog?.articleImage?.image.url ? (
            <div className="img-wrapper">
              {blog?.articleImage?.image?.gatsbyImageData ? (
                <Img
                  image={blog?.articleImage?.image.gatsbyImageData}
                  alt={blog?.articleImage?.internalName}
                  className="img-wrapper"
                />
              ) : (
                <Img
                  src={blog?.articleImage?.image.url}
                  alt={blog?.articleImage?.internalName}
                  className="img-wrapper"
                />
              )}
            </div>
          ) : (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center featured-blog-bg img-wrapper">
              <Img src={logo} alt="Shopmonkey Logo" />
            </div>
          )}
        </div>
        <div className="card-content">
          <h5> {blog?.title} </h5>
          <div className="blog-desc">
            {blog?.excerpt || blog?.metaTags?.description}
          </div>
        </div>
      </div>
      <div className="readmore">
        <PersistLink to={`/blog/${blog.slug}`}>Read more</PersistLink>
      </div>
    </Card>
  );
};

export default FeaturedCardNoBg;
